@use "sass:math";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700&display=swap");

$primaryColor: rgb(207, 122, 82);
$secondaryColor: rgb(241, 156, 121);
$monotoneColor: rgb(255, 235, 205);
$brownColor: rgb(20, 6, 1);
$borderColor: rgba(255, 255, 255, 0.2);
$benihanaRed: rgb(245, 4, 16);
$logoRed: rgb(236, 29, 36);

$globalFont: "jaf-domus", sans-serif;

// ! ===============================================================================================
// ! GLOBAL VARIABLES ==============================================================================
// ! ===============================================================================================
// * header/footer spacing
$headerHeight: 75px;
$footerHeight: $headerHeight;
$headerColor: rgba(0, 0, 0, 0);

// * section spacing
$sectionMargin: 100px;

// * page sizing
$minimumPageWidth: 350px;

body,
html {
  scroll-behavior: smooth;
  background: #f2b6bb;
}

body {
  min-width: $minimumPageWidth;
  font-family: $globalFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  min-height: 100vh;
  background: #f2b6bb;
  color: #435c73;

  display: flex;
  justify-content: center;
  align-items: center;
}

// ! ===============================================================================================
// ! GLOBAL FONT STYLES ============================================================================
// ! ===============================================================================================
$genericColor: #435c73;
$genericFont: $globalFont;
$headerFont: $globalFont;

p {
  text-align: center;
  // font-family: $headerFont;
  font-family: $globalFont;
  color: $genericColor;
  line-height: 2rem;
}

h1 {
  // background: blue;
  color: $genericColor;

  font-family: $headerFont;
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  margin-top: 50px;
}

h2 {
  font-family: $headerFont;
  // background: green;
  text-align: center;

  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 50px;
}
